import React, { Suspense, useEffect } from 'react';
import './app.css'
import dotenv from 'dotenv'
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import SocketFunctions from './VMS/Component/LandingPage/Context/SocketContext';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
// import ContextForSocket from './VMS/Component/LandingPage/Context/ContextForSocket'
// import SessionManagements from './VMS/Component/Common/Utils/SessionManagements';


// export const socketFunction = SocketContext()
const App = () => {
  useEffect(() => {
    // console.log("version is " , React.version)
    dotenv.config()
    Notification.requestPermission()
  })

  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  // const val = "gellooo"
  return (

    <>
      <Suspense fallback={<Loader />}>
        <div
          className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
          dir={direction ? 'rtl' : 'ltr'}
        >

          <ThemeSelector />
          {routing}
        </div>
      </Suspense >

    </>
  );
};

export default App;
