import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
// import SocketFunctions from '../../VMS/Component/LandingPage/Context/SocketContext';

const LightTheme = React.lazy(() => import('./LightTheme'));
const DarkTheme = React.lazy(() => import('./DarkTheme'));

// export const socketFunctions =createContext()

const ThemeSelector = ({ children }) => {

  const isDarkMode = useSelector((state) => state.customizer.isDark);


  return (
    <>
      <>{isDarkMode ? <DarkTheme /> : <LightTheme />}</>
      {children}

      {/* <h3 className='text-dark'>helllooo</h3> */}
    </>
  );
};

ThemeSelector.propTypes = {
  children: PropTypes.node,
};

export default ThemeSelector;
